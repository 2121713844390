/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';

.Staric{
    position: relative;
    right: 0;
    color:red;
}
.bg-dragonmart{
  background-color:#CE1F2C;
}
